<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-row>
                <el-col :lg="24">
                    <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                        <el-row>
                            <el-col :lg="24" style="line-height: 27px;">
                                <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：打印-进仓单</strong>
                            </el-col>
                        </el-row>
                    </div>
                    <el-row style="margin-bottom: 20px">
                        <el-button type="primary" size="mini" @click="lodopPrint" style="margin-bottom: 2px;margin-top: 2px">针式插件打印
                        </el-button>
                        <el-button type="primary" size="mini" @click="$router.back()" style="margin-bottom: 2px;margin-top: 2px">返回
                        </el-button>
                    </el-row>
                </el-col>
            </el-row>
            <div style="width: 100%;margin-right: 50px;float: left" v-for="index of pageSize" :key="index">
                <div id="myMain" style="width: 100%" ref="myMain">
                    <div :id="'tableDataMain'+index">
                        <div class="topHtml">
                            <div class="my-list-row">
                                <div class="my-list-col" style="width: 202mm;">
                                    <div class="my-list-row">
                                    <strong style=" font-size: 7mm ;margin-left: 80mm">外购进仓</strong>
                                    <a style="width: 65mm;margin-left: 7mm;font-size: 3mm">单号：{{formData.bill_id}}</a>
                                        <img border="0" src="@/assets/test.png" v-if="$store.getters.getRunTimeTest"
                                             style="z-index: -1; position: absolute; left:100px; top:200px;" />
                                        <img border="0" src="@/assets/zf.png" v-if="formData.is_cancel"
                                             style="z-index: -1; position: absolute; left:200px; top:150px;" />
                                    </div>
                                </div>
                            </div>
                            <div class="my-list-row">
                                <div class="my-list-col" style="width: 202mm;">
                                    <a style="width: 65mm;">日期：{{formatDate(formData.bill_date,'yyyy年MM月dd日')}}</a>
                                    <a style="width: 140mm;margin-left: 7mm">费用单位：{{formData.cost_of_the_unit}}</a>
                                    <a style="margin-left: 5mm" >{{formData.other_freight_fees_tax == '' || formData.other_freight_fees_tax == null ? '':'A'}}</a>
                                    <a style="width: 140mm;margin-left: 3mm">外省运费：{{$XEUtils.commafy(formData.other_freight_fees, {digits: 2})}}</a>
                                </div>
                            </div>
                            <div class="my-list-row">
                                <div class="my-list-col" style="width: 202mm;">
                                    <a style="font-size: 14px">供应商：{{formData.supplier}}</a>
                                    <a style="margin-left: 4mm" >{{formData.tax_tate_warehouse_bill == '' || formData.tax_tate_warehouse_bill == null ? '':'A'}}</a>
                                    <a style="width: 65mm;margin-left: 7mm">合计支数：{{formData.total_number}}</a>
                                    <a style="width: 140mm;margin-left: 7mm">总过磅重：{{formData.total_weighing_heavy}}</a>
                                    <a style="margin-left: 4mm">总金额：{{$XEUtils.commafy($XEUtils.add(formData.total_amount_of_product,formData.other_freight_fees), {digits: 2})}}</a>
                                </div>
                            </div>
                        </div>
                        <table border="1" cellspacing="0" cellpadding="0" style="width: 202mm;" >
                            <tr>
                                <!--<th rowspan="16" style="border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;line-height: 4mm;width: 1mm;font-size: 3mm; font-weight: initial;border-left: none;border-top: none;border-bottom: none">
                                    <a> m n e r p ▪ c o m  提 供 软 件 服 务</a>
                                </th>-->
                                <th style="height: 5mm;width: 8mm;border: solid 0.3mm;border-bottom: 0mm;border-top: 0mm;border-left: 0mm;text-align: center !important;">序</th>
                                <th style="height: 5mm;width: 10mm;border: solid 0.3mm;border-bottom: 0mm;border-top: 0mm;border-left: 0mm;text-align: center !important;">仓库</th>
                                <th style="height: 5mm;width: 12mm;border: solid 0.3mm;border-bottom: 0mm;border-top: 0mm;border-left: 0mm;text-align: center !important;">类别</th>
                                <th style="height: 5mm;width: 36mm;border: solid 0.3mm;border-bottom: 0mm;border-top: 0mm;border-left: 0mm;text-align: center !important;">实际规格</th>
                                <th style="height: 5mm;width: 11mm;border: solid 0.3mm;border-bottom: 0mm;border-top: 0mm;border-left: 0mm;text-align: center !important;">单位</th>
                                <th style="height: 5mm;width: 13mm;border: solid 0.3mm;border-bottom: 0mm;border-top: 0mm;border-left: 0mm;text-align: center !important;">支数</th>
                                <th style="height: 5mm;width: 14mm;border: solid 0.3mm;border-bottom: 0mm;border-top: 0mm;border-left: 0mm;text-align: center !important;">过磅重</th>
                                <th style="height: 5mm;width: 13mm;border: solid 0.3mm;border-bottom: 0mm;border-top: 0mm;border-left: 0mm;text-align: center !important;">支单价</th>
                                <th style="height: 5mm;width: 13mm;border: solid 0.3mm;border-bottom: 0mm;border-top: 0mm;border-left: 0mm;text-align: center !important;">吨单价</th>
                                <th style="height: 5mm;width: 22mm;border: solid 0.3mm;border-bottom: 0mm;border-top: 0mm;border-left: 0mm;text-align: center !important;">金额</th>
                                <th style="height: 5mm;width: 20mm;border: solid 0.3mm;border-bottom: 0mm;border-top: 0mm;border-left: 0mm;text-align: center !important;">外省运费</th>
                                <th style="height: 5mm;width: 26mm;border: solid 0.3mm;border-bottom: 0mm;border-top: 0mm;border-left: 0mm;border-right: 0mm;text-align: center !important;">产品备注</th>
                            </tr>
                            <tr style="width: 204mm; height: 5mm; " v-for="item in table[index-1]" :key="item.order_id">
                                <td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;"><a>{{item.order_id}}</a></td>
                                <td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;"><a>{{item.warehouse}}</a></td>
                                <td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;"><a>{{item.category}}</a></td>
                                <td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;"><a>{{item.specification}}</a></td>
                                <td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;"><a>{{item.unit}}</a></td>
                                <td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;"><a>{{item.single_count}}</a></td>
                                <td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;"><a>{{item.weighing_heavy}}</a></td>
                                <td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;"><a>{{formatMoney($_getDataByBlank(item.single_price))}}</a></td>
                                <td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;"><a>{{formatMoney($_getDataByBlank(item.tons_of_price))}}</a></td>
                                <td style="text-align: right !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;"><a>{{item.amount_of_product != null && item.amount_of_product != '' ? '¥'+formatMoney(item.amount_of_product):''}}</a></td>
                                <td style="text-align: right !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;"><a>{{item.other_freight_fees != null && item.other_freight_fees != '' ? '¥'+formatMoney(item.other_freight_fees):''}}</a></td>
                                <td style="text-align: right !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;border-right: 0mm;"><a>{{item.remark_stock}}</a></td>
                            </tr>
                        </table>
                        <div class="my-list-row" style="width: 202mm;">
                            <a style="width: 150mm">订单备注：{{formData.remark_warehouse_bill != '' && formData.remark_warehouse_bill != null ? formData.remark_warehouse_bill :''}}</a>
                            <a style="width: 85mm;margin-left: 7mm">打印日期：{{formatDate(new Date(),'yyyy年MM月dd日 HH:mm:ss')}}</a>
                            <a style="width: 50mm;margin-left: 7mm">制单人：{{formData.registrar}}</a>
                            <a style="margin-left: 7mm" >第{{index}}页，共{{pageSize}}页。</a>
                        </div>
                    </div>

                </div>
            </div>

        </el-main>
    </div>
</template>

<script>

    export default {

        data() {
            return {
                table: [],
                tableDataMain: [],
/*                tableDataMain1: [],
                tableDataMain2: [],
                tableDataMain3: [],*/
                //tableDataMainList: [],
                pageSize:1,
                dataSize:0,
                formData:{
                    clearing_form: '欠款',
                    other_expenses : '费用',
                    other_expenses_price : '',
                    customer_phone: '',
                    car_code : '',
                    bill_date: '',
                    bill_date_kd : '',
                    bill_date_dd : '',
                    bill_date_kdtzs : '',
                    contract_id : '',
                    delivery_date: '',
                    kick_back : '',
                    total_theoretical_weight: '',
                    bill_id_dd: '',
                    bill_id_kd : '',
                    bill_id_kdtzs : '',
                    bill_id: '',
                    wb_id: '',
                    settlement_days: '',
                    customer: '',
                    sales_man : '',
                    billing_company_name: '',
                    remark_warehouse_bill: '',
                    registrar: '',
                    total_amount: '',
                    practical_total_price : '',
                    total_number: '',
                    is_ticket: '',
                    last_name_warehouse_bill: '',
                    last_date_warehouse_bill: '',
                    bill_type: '发货单',
                    creat_date_warehouse_bill : '',
                    handled_by: '',
                    consignee: '',
                    tax_tate_warehouse_bill : '',
                    supplier: '',
                    additional_charges : '',
                    amount_of_product : '',
                    cost_of_the_unit : '',
                    total_weighing_heavy : '',
                    pounds_fees : '',//磅费
                    wind_cut_fees : '',//风割费
                    loading_car_fees : '',//装车费
                    transfer_fees : '',//中转费
                    freight_fees : '',//运费
                    procedure_fees:'',//手续费
                    taxes_fees : '',//税金
                    is_cancel: false,
               },
                input:''
           }
       },
        methods: {
            lodop(){
                //体温单打印
                const LODOP = this.$getLodop();
                const strStyle = "<!DOCTYPE html><style>.my-list-row {display: inline-block; width: 100%;} .my-list-col {float: left; width: 33.33%;} .my-top,.my-bottom {font-size: 12px;} .my-top {margin-bottom: 5px;} .my-bottom {margin-top: 30px; text-align: right;}</style>"
                //LODOP.SET_PRINT_PAGESIZE(0,210,93,"");//这里3表示纵向打印且纸高“按内容的高度”；1385表示纸宽138.5mm；45表示页底空白4.5mm
                //LODOP.ADD_PRINT_HTM(0,30,"100%","100%",strHTML);
                //LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT","Auto-Width");
                LODOP.SET_PRINT_PAGESIZE(1,2100,1400,"");//这里3表示纵向打印且纸高“按内容的高度”；1385表示纸宽138.5mm；45表示页底空白4.5mm
                //LODOP.ADD_PRINT_HTM(20,0,"100%","100%",strHTML);
                for (let i = 1; i < this.pageSize + 1; i++) {
                    if (i > 1) {
                        LODOP.NewPageA();
                   }
                    const strHTML= strStyle+"<body>"+document.getElementById("tableDataMain" + i).innerHTML+"</body>";
                    LODOP.ADD_PRINT_HTM(20,0,"100%","100%",strHTML);
                    /*LODOP.ADD_PRINT_HTM(15, 5, "100%", "100%","<!DOCTYPE html>"+ strStyle + "<body>" + document.getElementById("tableDataMain" + i).innerHTML + "</body>");
                    LODOP.SET_PRINT_STYLEA(0, "AngleOfPageInside", 90);*/
               }
                //LODOP.SET_PRINT_STYLEA(0,"AngleOfPageInside",90);//选择90度
                //LODOP.PRINT_INITA(10,10,762,533,"test");
                //148mm×210mm
                //LODOP.PRINT_INITA(10,10,210,148,"test");
 /*               if (this.pageSize > 1){
                    //LODOP.SET_PRINT_STYLEA(0,"AngleOfPageInside",90);
                    const strHTMLTwo = strStyle+"<body>"+document.getElementById("tableDataMain2").innerHTML+"</body>";
                    LODOP.NewPageA();
                    //LODOP.SET_PRINT_PAGESIZE(3,2000,1,"");//这里3表示纵向打印且纸高“按内容的高度”；1385表示纸宽138.5mm；45表示页底空白4.5mm
                    LODOP.SET_PRINT_PAGESIZE(1,2100,1400,"");//这里3表示纵向打印且纸高“按内容的高度”；1385表示纸宽138.5mm；45表示页底空白4.5mm
                    LODOP.ADD_PRINT_HTM(20,0,"100%","100%",strHTMLTwo);
                    //LODOP.ADD_PRINT_HTM(0,0,"100%","100%",strHTMLTwo);
                    //LODOP.SET_PRINT_STYLEA(0,"AngleOfPageInside",90);
               }
                if (this.pageSize > 2){
                    //LODOP.SET_PRINT_STYLEA(0,"AngleOfPageInside",90);
                    const strHTMLThree = strStyle+"<body>"+document.getElementById("tableDataMain3").innerHTML+"</body>";
                    LODOP.NewPageA();
                    //LODOP.SET_PRINT_PAGESIZE(3,2000,1,"");//这里3表示纵向打印且纸高“按内容的高度”；1385表示纸宽138.5mm；45表示页底空白4.5mm
                    LODOP.SET_PRINT_PAGESIZE(1,2100,1400,"");//这里3表示纵向打印且纸高“按内容的高度”；1385表示纸宽138.5mm；45表示页底空白4.5mm
                    LODOP.ADD_PRINT_HTM(20,0,"100%","100%",strHTMLThree);
                    //LODOP.ADD_PRINT_HTM(0,0,"100%","100%",strHTMLTwo);
                    //LODOP.SET_PRINT_STYLEA(0,"AngleOfPageInside",90);
               }*/
                return LODOP;
           },
            splitTableDataMain(){
/*                this.tableDataMain1 = [];
                this.tableDataMain2 = [];
                this.tableDataMain3 = [];
                for (let i = 0; i < 15; i++) {
                    this.tableDataMain1.push(this.tableDataMain[i]);
               }
                for (let i = 15; i < 30; i++) {
                    this.tableDataMain2.push(this.tableDataMain[i]);
               }
                /!*for (let i = 20; i < 30; i++) {
                    this.tableDataMain3.push(this.tableDataMain[i]);
               }*!/
                this.tableDataMainList.push(this.tableDataMain1);
                this.tableDataMainList.push(this.tableDataMain2);*/
                //this.tableDataMainList.push(this.tableDataMain3);

                let list = [];
                for (let i = 0; i < this.tableDataMain.length; i++) {
                    list.push(this.tableDataMain[i]);
                    if (list.length == 15) {
                        this.table.push(list);
                        list = [];
                   }
               }
                this.table.push(list);
           },
            lodopPrint(){
                const LODOP = this.lodop()
                LODOP.PREVIEW()
                //LODOP.PRINT_SETUP();//打印维护
                //LODOP.PRINT_DESIGN()
           },
            indexMethod(index) {
                return index +1;
           },searchWarehouseBill() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$axios({
                    method: 'post',
                    url: '/admin/warehouseEntryBill/search',
                    params: {
                        bill_id: this.formData.bill_id,
                        wb_id: this.formData.wb_id,
                   },
               }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.formData = response.data.warehouseBill;
                        //this.tableDataMain = this.$_createRowData(response.data.stocks);
                        this.tableDataMain = response.data.stocks;
                        this.dataSize = response.data.dataSize;
                        this.pageSize = this.dataSize % 15 == 0 ? this.dataSize / 15 : this.$XEUtils.toInteger((this.dataSize / 15)) + 1;
                        this.splitTableDataMain();
                        /*if(this.dataSize > 20){
                            this.pageSize = 3;
                       }*/
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
       },
        created() {
            this.formData.bill_id = this.$route.query.bill_id;
            this.formData.wb_id = this.$route.query.wb_id;
            this.searchWarehouseBill();
       }
   }


</script>

<style scoped>
    th, td {
        text-align: initial !important;
   }
    .my-list-row {
        display: inline-block;
        width: 100%;
   }
    .my-list-col {
        float: left;
        width: 33.33%;
   }
    .my-top,.my-bottom {
        font-size: 12px;
   }
    .my-top {
        margin-bottom: 5px;
   }
    .my-bottom {
        margin-top: 30px;
        text-align: right;
   }
</style>
